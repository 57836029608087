//
// Root
//

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $root-font-size-lg !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }
}

body {
  display: flex;
  flex-direction: column;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

canvas {
  user-select: none;
}

// Angular integration
router-outlet {
  display: none;
}

.cust-card {
  max-width: 600px;
  margin: 0 auto;
  & .title {
  }
  & .payment-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #dbe0e3;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    &:hover {
      z-index: 2;
      background: #c2dbff;
      box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%),
        0 1px 3px 1px rgb(60 64 67 / 15%);
      & .action-wrapper {
        display: block;
      }
    }
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
    }
    & .configure-txt {
      color: #a1a5b7;
    }
    & .action-wrapper {
      display: none;
      position: absolute;
      right: 58px;
      z-index: 9999;
      & .action-style {
        padding: 10px;
        &:hover {
          background-color: #a1a5b7;
          border-radius: 50%;
          padding: 10px;
        }
      }
    }
  }
  & .gateway-logo {
    height: 50px;
  }
}

.payment-gateway {
  & .payment-wrap:hover {
    background: transparent;
    box-shadow: none;
  }
}
